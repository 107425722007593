import React, { useState, useEffect } from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function EditUsers({ user, currentPage, apiLink }) {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({email: "", admin: false});

  useEffect(() => {
    const getUsers = `${apiLink}/api/users`;
    axios
      .get(getUsers)
      .then((res) => {
        console.log(res);
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleNewUser = (event) => {
    const newObject = {...newUser};
    if(event.target.type === "text"){
      newObject["email"] = event.target.value;
    }else{
      newObject["admin"] = !newObject["admin"];
    }
    setNewUser(newObject);
  }

  const handleBack = (event) => {
    currentPage.setCurrentPage("admin-menu");
  };

  const addUser = () => {
    const addUser = `${apiLink}/api/user`;
    axios.post(addUser, newUser)
      .then((res) => {
        console.log(res);
        setUsers(res.data);
        const newObject = {...newUser};
        newObject["email"] = "";
        newObject["admin"] = false;
        setNewUser(newObject);
      })
      .catch((res) => {
        console.log(res);
      })
  }

  const deleteUser = (event) => {
    const userId = event.target.className.split(" ")[0];
    const deleteUser = `${apiLink}/api/user?user=${userId}`;
    axios
      .delete(deleteUser)
      .then((res) => {
        console.log(res);
        setUsers(res.data);
      })
      .catch((res) => {
        console.log(res);
      });
  }

  const getConfirmation = (event) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUser(event),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <div className="edit-user">
      {users.map((user, indx) => {
        return (
          <div className="user" key={indx}>
            <p>{user.email}</p>
            <i
              className={`${user._id} fa fa-trash`}
              aria-hidden="true"
              onClick={getConfirmation}
            ></i>
          </div>
        );
      })}

      <div className="add-user">
        Add new user:
        <input
          type="text"
          value={newUser.email}
          onChange={handleNewUser}
          placeholder="Enter email"
        />
        <label>
          Add as admin:
          <input
            type="checkBox"
            value={newUser.admin}
            onChange={handleNewUser}
          />
        </label>
        <button onClick={addUser}>Add new user</button>
      </div>

      <button className="back-btn" onClick={handleBack}>
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        Back
      </button>
    </div>
  );
}

export default EditUsers;
