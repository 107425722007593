import React, {useState, useEffect} from "react";
import axios from 'axios';
import moment from "moment";
import Tags from "./Tags"

function MainPage({posts, apiLink}) {
  const [selectedTags, setSelectedTags] = useState(["All"]);
  useEffect(() => {
    const getPosts = `${apiLink}/api/posts`;
    console.log(getPosts);
    axios
      .get(getPosts)
      .then((res) => {
        console.log(res);
        posts.setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const getPostsByTags = `${apiLink}/api/posts`;
    axios
      .post(getPostsByTags, {tags: selectedTags})
      .then((res) => {
        console.log(res);
        posts.setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedTags])

  return (
    <main className="container">
      <Tags
        selectedTags={{selectedTags, setSelectedTags}}
        apiLink={apiLink}
      />
      {posts.posts.map((post, indx) => {
        const time = moment(new Date(post.time)).format('MMMM d, YYYY')
        return (
          <div className="post" key={indx}>
            <img src={post.image} />
            <div className="post-info">
              <div className="tags">{post.tags.map((tag, i)=>{
                return(
                  <p className="tag">{tag}</p>
                )
              })}</div>
              <h1 className="title">{post.title}</h1>
              <p>
                <b>By: </b>
                {post.author} <b>On: </b>
                {time}
              </p>
            </div>
          </div>
        );
      })}
    </main>
  );
}

export default MainPage;
