import React from 'react';

function Header({currentPage}){

  const changePage = (event) => {
    if(event.target.className === "admin"){
      currentPage.setCurrentPage("admin-menu");
    }else{
      currentPage.setCurrentPage("main");
    }
  }

  return (
    <header>
      <div className="logo">
        <img
          src="./logo.svg"
          alt="main icon"
          className="logo-image"
          onClick={changePage}
        />
      </div>
      <div className="admin" onClick={changePage}>
        <i className="fa fa-user" aria-hidden="true"></i>
        Admin
      </div>
    </header>
  );
}

export default Header;
