import React from "react";

function Footer() {

  return (
    <footer>
      <div className="footer-socials">
        <a href="#">
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href="#">
          <i className="fa fa-linkedin" aria-hidden="true"></i>
        </a>
        <a href="#">
          <i className="fa fa-twitter" aria-hidden="true"></i>
        </a>
        <a href="#">
          <i className="fa fa-instagram" aria-hidden="true"></i>
        </a>
      </div>
      <div className="footer-info">
        SCALEUP. group © 2022 | <a href="#">Privacy Policy</a> |
        <a href="https://www.cookiesandyou.com/"> Cookies Policy</a>
      </div>
    </footer>
  );
}

export default Footer;
