import React, {useState, useEffect} from "react";
import axios from 'axios';

function AddPost({user, currentPage, apiLink}) {
  const [newPost, setNewPost] = useState({ title: "", image: "", tags: [] });
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const getTags = `${apiLink}/api/tags`;
    axios
      .get(getTags)
      .then((res) => {
        res.data.splice(0, 1);
        console.log(res.data);
        setTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const setImage = (event) => {
    const newObject = { ...newPost };
    newObject["image"] = event.target.files[0];
    setNewPost(newObject);
  };

  const setTitle = (event) => {
    const newObject = { ...newPost };
    newObject["title"] = event.target.value;
    setNewPost(newObject);
  };

  const setTag = (event) => {
    const newObject = {...newPost};
    if(newObject.tags.includes(event.target.name)){
      const indx = newObject.tags.indexOf(event.target.name);
      newObject.tags.splice(indx, 1);
    }else{
      newObject.tags.push(event.target.name);
    }
    setNewPost(newObject);
  }

  const submitPost = () => {
    if(!newPost.title || !newPost.image || !newPost.tags.length){
      alert("The post has to have a title, an image, and tags!");
    }else{
      const submitPostReq = `${apiLink}/api/post`;
      const bodyFormData = new FormData();
      bodyFormData.append("title", newPost.title);
      bodyFormData.append("image", newPost.image);
      bodyFormData.append("tags", JSON.stringify(newPost.tags));
      bodyFormData.append("author", user.user.name);
      axios
        .post(submitPostReq, bodyFormData, {})
        .then(function (response) {
          console.log(response);
          setNewPost({ title: "", image: "", tags: [] });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleBack = () => {
    currentPage.setCurrentPage("admin-menu");
  }

  return (
    <div className="submit-form">
      {newPost.image ? (
        <img
          src={URL.createObjectURL(newPost.image)}
          className="post-image"
          alt="Post picture"
        />
      ) : (
        ""
      )}
      <input className="upload-image" type="file" onChange={setImage} />
      <input
        className="add-text"
        type="text"
        value={newPost.title}
        onChange={setTitle}
        placeholder="Add title"
      />
      <div className="tags">
        {tags.map((tag, indx) => {
          const id = tag.name.replace(/\s+/gi, "_");
          return (
            <div key={indx}>
              <input
                id={id}
                type="checkbox"
                name={tag.name}
                checked={newPost.tags.includes(tag.name)}
                onChange={setTag}
              />
              <label htmlFor={id}>{tag.name}</label>
            </div>
          );
        })}
      </div>
      <button onClick={submitPost}>Submit post</button>
      <button className="back-btn" onClick={handleBack}>
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        Back
      </button>
    </div>
  );
}

export default AddPost;
