import React, {useState, useEffect} from "react";
import axios from "axios";

function Tags({ selectedTags, apiLink }) {
  // const [tags, setTags] = useState(["All", "JS", "Java", "HTML", "CSS", "PHP"]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const getTags = `${apiLink}/api/tags`;
    axios
      .get(getTags)
      .then((res) => {
        console.log(res);
        setTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleTag = (event) =>{
    const newTag = event.target.name;
    let newTags = [...selectedTags.selectedTags];
    if(newTags.includes(newTag)){
      if(newTags.length > 1){
        const indx = newTags.indexOf(newTag);
        newTags.splice(indx, 1);
      }
    }else{
      if(newTag === "All"){
        newTags = ["All"];
      }else{
        newTags.push(newTag);
        if(newTags.includes("All")){
          const indx = newTags.indexOf("All");
          newTags.splice(indx, 1);
        }
      }
    }
    selectedTags.setSelectedTags(newTags);
  }

  return (
    <div className="sorting">
      {tags.map((tag, indx) => {
        return (
          <div key={indx} className="tag">
            <input
              type="checkbox"
              name={tag.name}
              checked={selectedTags.selectedTags.includes(tag.name)}
              onChange={handleTag}
              id={tag.name.replace(/\s+/gi, "_")}
            />
            <label  htmlFor={tag.name.replace(/\s+/gi, "_")}>
              {tag.name}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default Tags;
