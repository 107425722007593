import React, { useState, useEffect } from "react";
import axios from "axios";
import { toBeEmptyDOMElement } from "@testing-library/jest-dom/dist/matchers";

function EditPost({ editingPost, user, currentPage, apiLink }) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if(localStorage.getItem("editingPost")){
      editingPost.setEditingPost(JSON.parse(localStorage.getItem("editingPost")));
    }
    const getTags = `${apiLink}/api/tags`;
    axios
      .get(getTags)
      .then((res) => {
        res.data.splice(0, 1);
        console.log(res.data);
        setTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const setImage = (event) => {
    const newObject = { ...editingPost.editingPost };
    newObject["newImage"] = event.target.files[0];
    editingPost.setEditingPost(newObject);
  };

  const setTitle = (event) => {
    const newObject = { ...editingPost.editingPost };
    newObject["title"] = event.target.value;
    editingPost.setEditingPost(newObject);
  };

  const setTag = (event) => {
    const newObject = { ...editingPost.editingPost };
    if (newObject.tags.includes(event.target.name)) {
      const indx = newObject.tags.indexOf(event.target.name);
      newObject.tags.splice(indx, 1);
    } else {
      newObject.tags.push(event.target.name);
    }
    editingPost.setEditingPost(newObject);
  };

  const updatePost = () => {
    const updatePostReq = `${apiLink}/api/post`;
    const bodyFormData = new FormData();
    bodyFormData.append("_id", editingPost.editingPost._id);
    bodyFormData.append("title", editingPost.editingPost.title);
    bodyFormData.append("image", editingPost.editingPost.image);
    bodyFormData.append("newImage", editingPost.editingPost.newImage);
    bodyFormData.append("tags", JSON.stringify(editingPost.editingPost.tags));
    bodyFormData.append("author", user.user.name);
    axios
      .put(updatePostReq, bodyFormData, {})
      .then(function (response) {
        console.log(response);
        currentPage.setCurrentPage("choose-post");
        localStorage.setItem("editingPost", "");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleBack = () => {
    currentPage.setCurrentPage("choose-post");
  };

  return (
    <div className="submit-form">
      {console.log(editingPost)}
      {editingPost.editingPost.image || editingPost.editingPost.newImage ? (
        <img
          src={
            editingPost.editingPost.newImage
              ? URL.createObjectURL(editingPost.editingPost.newImage)
              : editingPost.editingPost.image
          }
          className="post-image"
          alt="Post picture"
        />
      ) : (
        ""
      )}
      <input className="upload-image" type="file" onChange={setImage} />
      <input
        className="add-text"
        type="text"
        value={editingPost.editingPost.title}
        onChange={setTitle}
        placeholder="Add title"
      />
      <div className="tags">
        {tags.map((tag, indx) => {
          const id = tag.name.replace(/\s+/gi, "_");
          return (
            <div key={indx}>
              <input
                id={id}
                type="checkbox"
                name={tag.name}
                checked={editingPost.editingPost.tags.includes(tag.name)}
                onChange={setTag}
              />
              <label htmlFor={id}>{tag.name}</label>
            </div>
          );
        })}
      </div>
      <button onClick={updatePost}>Submit post</button>
      <button className="back-btn" onClick={handleBack}>
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        Back
      </button>
    </div>
  );
}

export default EditPost;
