import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Tags from "./Tags";

function ChoosePost({ editingPost, user, currentPage, apiLink }) {
  const [posts, setPosts] = useState([]);
  const [selectedTags, setSelectedTags] = useState(["All"]);

  useEffect(() => {
    const getPosts = `${apiLink}/api/posts`;
    console.log(getPosts);
    axios
      .get(getPosts)
      .then((res) => {
        console.log(res);
        setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const getPostsByTags = `${apiLink}/api/posts`;
    axios
      .post(getPostsByTags, { tags: selectedTags })
      .then((res) => {
        console.log(res);
        setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedTags]);

  const handleDelete = (event) => {
    const deletePosts = `${apiLink}/api/post?post=${event.target.className}`;
    console.log(deletePosts);
    axios
      .delete(deletePosts)
      .then((res) => {
        console.log(res);
        setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getConfirmation = (event) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this post?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(event),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleEdit = (event) => {
    const postId = event.target.className;
    const post = posts.find(post => post._id == postId);
    editingPost.setEditingPost(post);
    localStorage.setItem("editingPost", JSON.stringify(post));
    currentPage.setCurrentPage("edit-post");
  }

  const handleBack = (event) => {
    currentPage.setCurrentPage("admin-menu");
  }

  return (
    <div className="delete-form">
      <Tags
        selectedTags={{ selectedTags, setSelectedTags }}
        apiLink={apiLink}
      />
      {posts.map((post, indx) => {
        const time = moment(new Date(post.time)).format("MMMM d, YYYY");
        return (
          <div className="post" key={indx}>
            <img src={post.image} />
            <div className="post-info">
              <div className="tags">
                {post.tags.map((tag, i) => {
                  return (
                    <p className="tag" key={i}>
                      {tag}
                    </p>
                  );
                })}
              </div>
              <h1 className="title">{post.title}</h1>
              <p>
                <b>By:</b>
                {post.author} <b>On:</b> {time}
              </p>
            </div>
            <div className="post-action">
              <button className={`${post._id}`} onClick={getConfirmation}>
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                Delete
              </button>
              <button className={`${post._id}`} onClick={handleEdit}>
                <i className="fa fa-text-width" aria-hidden="true"></i>
                Edit
              </button>
            </div>
          </div>
        );
      })}
      <button className="back-btn" onClick={handleBack}>
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
        Back
      </button>
    </div>
  );
}

export default ChoosePost;
