import React, { useState, useEffect } from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function EditTags({ currentPage, apiLink }) {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState({ name: ""});

  useEffect(() => {
    const getTags = `${apiLink}/api/tags`;
    axios
      .get(getTags)
      .then((res) => {
        console.log(res);
        setTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleNewTag = (event) => {
    const newObject = { ...newTag };
    newObject["name"] = event.target.value;
    setNewTag(newObject);
  };

  const handleBack = (event) => {
    currentPage.setCurrentPage("admin-menu");
  };

  const addTag = () => {
    const addTag = `${apiLink}/api/tag`;
    axios
      .post(addTag, newTag)
      .then((res) => {
        console.log(res);
        setTags(res.data);
        const newObject = { ...newTag };
        newObject["name"] = "";
        setNewTag(newObject);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const deleteTag = (event) => {
    const userId = event.target.className.split(" ")[0];
    const deleteTag = `${apiLink}/api/tag?tag=${userId}`;
    axios
      .delete(deleteTag)
      .then((res) => {
        console.log(res);
        setTags(res.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const getConfirmation = (event) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this tag?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTag(event),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div className="edit-tags">
      {tags.map((tag, indx) => {
        return (
          <div className="user" key={indx}>
            <p>{tag.name}</p>
            <i
              className={`${tag._id} fa fa-trash`}
              aria-hidden="true"
              onClick={getConfirmation}
            ></i>
          </div>
        );
      })}

      <div className="add-user">
        Add new tag:
        <input
          type="text"
          value={newTag.name}
          onChange={handleNewTag}
          placeholder="Enter tag"
        />
        <button onClick={addTag}>Add new tag</button>
      </div>

      <button className="back-btn" onClick={handleBack}>
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        Back
      </button>
    </div>
  );
}

export default EditTags;
