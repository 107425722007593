import React, {useState} from "react";
import axios from "axios";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  googleLogout,
} from "@react-oauth/google";
import EditPost from './EditPost.js';
import AddPost from './AddPost.js';
import ChoosePost from './ChoosePost.js';
import EditTags from './EditTags.js'
import EditUsers from "./EditUsers.js";

function Admin({user, currentPage, apiLink}) {
  const [editingPost, setEditingPost] = useState("");

  const handleLogin = (res) => {
    const loginReq = `${apiLink}/api/auth`;
    axios
      .post(loginReq, { jwtToken: res.credential })
      .then(function (response) {
        console.log(response);
        user.setUser(response.data);
        localStorage.setItem("jwtToken", res.credential);
        axios.defaults.headers.common["x-auth-token"] = res.credential;
        currentPage.setCurrentPage("admin-menu");
      })
      .catch(function (error) {
        console.log(error);
        error.message = "You don't have access :(";
      });
  }

  const handleLogout = () => {
    user.setUser({});
    localStorage.clear();
    googleLogout();
  }

  const handleMenu = (event) => {
    currentPage.setCurrentPage(event.target.className);
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <main className="container">
        {user.user.email ? (
          <>
            {currentPage.currentPage === "admin-menu" ? (
              <div className="admin-menu">
                <button className="add-post" onClick={handleMenu}>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                  Add post
                </button>
                <button className="choose-post" onClick={handleMenu}>
                  <i className="fa fa-text-width" aria-hidden="true"></i>
                  Edit post
                </button>
                <button className="choose-post" onClick={handleMenu}>
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                  Delete post
                </button>
                <button className="edit-tags" onClick={handleMenu}>
                  <i className="fa fa-slack" aria-hidden="true"></i>
                  Edit tags
                </button>
                <button className="edit-users" onClick={handleMenu}>
                  <i className="fa fa-users" aria-hidden="true"></i>
                  Edit users
                </button>
                <button onClick={handleLogout}>
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                  Logout
                </button>
              </div>
            ) : currentPage.currentPage === "add-post" ? (
              <AddPost
                user={user}
                currentPage={currentPage}
                apiLink={apiLink}
              />
            ) : currentPage.currentPage === "choose-post" ? (
              <ChoosePost
                editingPost={{ editingPost, setEditingPost }}
                user={user}
                currentPage={currentPage}
                apiLink={apiLink}
              />
            ) : currentPage.currentPage === "edit-post" ? (
              <EditPost
                editingPost={{ editingPost, setEditingPost }}
                user={user}
                currentPage={currentPage}
                apiLink={apiLink}
              />
            ) : currentPage.currentPage === "edit-tags" && user.user.admin ? (
              <EditTags
                currentPage={currentPage}
                apiLink={apiLink}
              />
            ) : currentPage.currentPage === "edit-users" && user.user.admin ? (
              <EditUsers
                user={user}
                currentPage={currentPage}
                apiLink={apiLink}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <div>
            <GoogleLogin
              onSuccess={handleLogin}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </div>
        )}
      </main>
    </GoogleOAuthProvider>
  );
}

export default Admin;
