import React, {useState, useEffect} from 'react';
import axios from "axios";
import Header from './Header'
import MainPage from './MainPage'
import Admin from './Admin';
import Footer from "./Footer"
// const apiLink = "http://localhost:8080"; 
// const apiLink = "https://scaleup-1sheet.loca.lt";
const apiLink = "https://first-server-346209.lm.r.appspot.com";


function App() {
  const [currentPage, setCurrentPage] = useState(localStorage.getItem("page") ? localStorage.getItem("page") : "main");
  const [user, setUser] = useState({});
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (!user.admin && jwtToken) {
      const profileUploadReq = `${apiLink}/api/auth`;
      axios
        .post(profileUploadReq, { jwtToken: jwtToken })
        .then(function (response) {
          setUser(response.data);
        })
        .catch(function (error) {
          console.log(error);
          localStorage.clear();
        });
    }
  });

  useEffect(()=>{
    localStorage.setItem("page", currentPage);
  }, [currentPage])

  return (
    <div className="app">
      <Header 
        currentPage={{currentPage, setCurrentPage}}
      />
      {currentPage === "main" ? (
         <MainPage 
          posts={{posts, setPosts}}
          apiLink={apiLink}
         />
      ) : (
        <Admin 
          user={{user, setUser}}
          currentPage={{currentPage, setCurrentPage}}
          apiLink={apiLink}
        />
      )}
      <Footer/>
    </div>
  );
}

export default App;
